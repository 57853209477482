import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

import Register from "../views/Register.vue";
import UniqueHub from "../views/UniqueHub.vue";
import ResetPassword from "../views/resetPassword.vue";
import Cookies from "js-cookie";

// Listen for the 'beforeunload' event
window.addEventListener("beforeunload", (event) => {
  // Check if the tab is being closed
  if (event.clientX < 0 && event.clientY < 0) {
    // Clear token and user information when the tab is closed
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});
const authGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/" || to.path === "/forget")
  ) {
    next("/dashboard");
  } else if (isAuthenticated || to.path === "/login" || to.path === "/forget") {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },
  { path: "/touid/:id", component: UniqueHub, beforeEnter: authGuard },
  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/agencies",
    name: "agencies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Agency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agency" },
  },
  {
    path: "/srt",
    name: "srt",
    component: () => import(/* webpackChunkName: "about" */ "../views/Srt.vue"),
    beforeEnter: authGuard,
    meta: { title: "Srt" },
  },
  {
    path: "/unique-id-validation",
    name: "unique-id-validation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UniqueId.vue"),
    beforeEnter: authGuard,
    meta: { title: "Unique ID Validation" },
  },
  {
    path: "/validation",
    name: "validation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Validation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Validation" },
  },

  {
    path: "/missing-information",
    name: "Missing Information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MissingInformation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Missing Information" },
  },
  {
    path: "/states",
    name: "states",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/State.vue"),
    beforeEnter: authGuard,
    meta: { title: "State" },
  },
  {
    path: "/agency-accounts",
    name: "agencyAccounts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AgencyAccount.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agency Account" },
  },
  {
    path: "/account-agencies",
    name: "accountAgencies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AccountAgency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Account Agency" },
  },
  {
    path: "/payment-agencies",
    name: "paymentAgency",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PaymentAgency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Payment Agency" },
  },
  {
    path: "/srt-agencies",
    name: "srtAgency",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SrtAgency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Srt Agency" },
  },
  {
    path: "/citation-agencies",
    name: "citationAgency",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/citationAgency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Citation Agency" },
  },
  {
    path: "/agency-coverage",
    name: "agencyCoverage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AgencyCoverage.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agency Coverage" },
  },
  {
    path: "/categories",
    name: "File Categories",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Categories.vue"),
    beforeEnter: authGuard,
    meta: { title: "Categories" },
  },
  {
    path: "/unbilled",
    name: "Varification",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Verification.vue"),
    beforeEnter: authGuard,
    meta: { title: "Verification" },
  },
  {
    path: "/payments",
    name: "Payments",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Payment.vue"),
    beforeEnter: authGuard,
    meta: { title: "paymentLong" },
  },
  {
    path: "/masterfile",
    name: "Masterfiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Masterfile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Masterfile" },
  },
  {
    path: "/daily-scans",
    name: "Daily Scans",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Scan.vue"),
    beforeEnter: authGuard,
    meta: { title: "Scans" },
  },
  {
    path: "/my-srt",
    name: "My SRT",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MySRT.vue"),
    beforeEnter: authGuard,
    meta: { title: "My Srts" },
  },
  {
    path: "/srt-raws",
    name: "SRT Raw",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SrtRaw.vue"),
    beforeEnter: authGuard,
    meta: { title: "Srt Raw" },
  },
  {
    path: "/my-validation",
    name: "My Validation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyValidation.vue"),
    beforeEnter: authGuard,
    meta: { title: "My Validation" },
  },
  {
    path: "/validate",
    name: "View Validation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ViewValidation.vue"),
    beforeEnter: authGuard,
    meta: { title: "View Validation" },
  },
  {
    path: "/profile",
    name: "My Profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Profile" },
  },

  {
    path: "/my-srtraw",
    name: "scan raws",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SrtRawCard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Scan Raw" },
  },
  {
    path: "/past-turnover",
    name: "Reassign scan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SrtReassign.vue"),
    beforeEnter: authGuard,

    meta: { title: "Reassign scan" },
  },
  {
    path: "/clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Client.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients" },
  },
  {
    path: "/uniqueid-scans",
    name: "UniqueID Scans",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UniqueIdCard.vue"),
    beforeEnter: authGuard,
    meta: { title: "UniqueID Scans" },
  },

  {
    path: "/distinct-lp",
    name: "Distinct LPs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DistinctLP.vue"),
    beforeEnter: authGuard,
    meta: { title: "Distinct LPs" },
  },
  {
    path: "/online-transactions",
    name: "Online Transactions LPs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/onlineTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Online Transactions LPs" },
  },
  {
    path: "/invoicable-transactions",
    name: "Invoicable Transactions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/invoicable.vue"),
    beforeEnter: authGuard,
    meta: { title: "Invoicable Transactions" },
  },

  {
    path: "/drop-box",
    name: "Drop Box",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DropBox.vue"),
    beforeEnter: authGuard,
    meta: { title: "Drop Box" },
  },

  {
    path: "/assigned-scans",
    name: "Assigned Scans",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AssignedScans.vue"),
    beforeEnter: authGuard,
    meta: { title: "Assigned Scans" },
  },
  {
    path: "/select-files",
    name: "Drop Box Select Files",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DropBoxFileUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "Drop Box Select Files" },
  },
  {
    path: "/scanned-files",
    name: "Scanned Files",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ScannedFiles.vue"),
    beforeEnter: authGuard,
    meta: { title: "Scanned Files" },
  },
  {
    path: "/unprocessable-files",
    name: "Unprocessable Files",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Unprocessable.vue"),
    beforeEnter: authGuard,
    meta: { title: "Unprocessable Files" },
  },
  {
    path: "/s4p",
    name: "Scheduled For payment",
    component: () => import(/* webpackChunkName: "about" */ "../views/S4P.vue"),
    beforeEnter: authGuard,
    meta: { title: "Scheduled For payment" },
  },
  {
    path: "/search",
    name: "Search For Scan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
    beforeEnter: authGuard,
    meta: { title: "Search For Scan" },
  },
  {
    path: "/daily-report",
    name: "Report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dailyReport.vue"),
    beforeEnter: authGuard,
    meta: { title: "Report" },
  },
  {
    path: "/s4p-citations",
    name: "S4PCitations",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/S4Pcitation.vue"),
    beforeEnter: authGuard,
    meta: { title: "S4PCitations" },
  },
  {
    path: "/s4p-invoices",
    name: "S4P Invoices",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/S4PInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "S4P Invoices" },
  },

  {
    path: "/citation-view",
    name: "Citations",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CitationView.vue"),
    beforeEnter: authGuard,
    meta: { title: "Citations" },
  },
  {
    path: "/scanned-files-view",
    name: "scannedFilesView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterfileView.vue"),
    beforeEnter: authGuard,
    meta: { title: "Scanned Files View" },
  },
  {
    path: "/view-scan",
    name: "ViewScans",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ViewScan.vue"),
    beforeEnter: authGuard,
    meta: { title: "View Scans" },
  },
  {
    path: "/view-transaction",
    name: "Viewtransaction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/S4PTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "View Transactions" },
  },
  {
    path: "/user-report",
    name: "User Report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/userReport.vue"),
    beforeEnter: authGuard,
    meta: { title: "User Report" },
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
    beforeEnter: authGuard,
    meta: { title: "Not Found" },
  },
  {
    path: "/collection",
    name: "Collection Scans",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/collectionScans.vue"),
    beforeEnter: authGuard,
    meta: { title: "Collection Scans" },
  },
  {
    path: "/transactions",
    name: "SRT Transactions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Transactionsview.vue"),
    beforeEnter: authGuard,
    meta: { title: "SRT Transactions" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);
// router.beforeEach((to, from, next) => {
//   const allowedRoutes = [
//     "/scanned-files-view",
//     "/collection",
//     "/scanned-files",
//   ];

//   if (!allowedRoutes.includes(to.path)) {
//     sessionStorage.removeItem("savedFilters"); // Clear filters if navigating outside
//   }
// });

export default router;
