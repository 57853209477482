<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/dashboard"
      style="background-color: white"
    >
      <!-- <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div> -->
      <img
        src="../../assets/favicon.png"
        style="height: 35px; width: 160px"
        alt="LOGO"
      />
      <!-- <span class="ms-1 font-weight-bold">Innovative Toll</span> -->
      <!-- <div class="sidebar-brand-text mx-3">Innovative Toll</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item pl-1">
      <!-- <a class="nav-link" href="/dashboard"> -->
      <router-link class="nav-link" to="/dashboard"
        ><i class="fas fa-fw fa-tachometer-alt"></i>
        <span>DASHBOARD</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />
    <!-- Heading -->
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-cog"></i>
        <span>SRT PROCESSING</span>
      </a>
      <div
        id="collapse"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">SRT</h6>
          <router-link class="collapse-item" to="/daily-scans"
            >DAILY SCAN</router-link
          >
          <a
            class="collapse-item collapsed d-flex justify-content-between align-items-center"
            href="#"
            data-toggle="collapse"
            data-target="#collapseSRT"
            aria-expanded="false"
            aria-controls="collapseSRT"
          >
            <span>SCAN PROCESSING</span>
            <i class="fas fa-fw fa-caret-down text-secondary"></i>
          </a>
          <div id="collapseSRT" class="collapse" aria-labelledby="headingSRT">
            <div class="bg-white py-2 collapse-inner rounded">
              <router-link class="collapse-item" to="/srt"
                >NEW SCAN</router-link
              >
              <router-link class="collapse-item" to="/my-srt"
                >MY SCAN</router-link
              >
              <router-link class="collapse-item" to="/assigned-scans"
                >ASSIGNED SCANS</router-link
              >

              <h6 v-if="this.isAdmin" class="collapse-header">Admin:</h6>
              <router-link
                v-if="this.isAdmin"
                class="collapse-item"
                to="/past-turnover"
                >PAST TURNOVER</router-link
              >
            </div>
          </div>
          <a
            class="collapse-item collapsed d-flex justify-content-between align-items-center"
            href="#"
            data-toggle="collapse"
            data-target="#collapseVal"
            aria-expanded="false"
            aria-controls="collapseVal"
          >
            <span>SRT VALIDATIONS</span>
            <i class="fas fa-fw fa-caret-down text-secondary"></i>
          </a>
          <div id="collapseVal" class="collapse" aria-labelledby="headingVal">
            <div class="bg-white py-2 collapse-inner rounded">
              <!-- Add SRT-related links here -->
              <router-link class="collapse-item" to="/my-srtraw"
                >FORMATING</router-link
              >
              <router-link class="collapse-item" to="/validation"
                >VALIDATIONS</router-link
              >
              <router-link class="collapse-item" to="/my-validation"
                >MY VALIDATION</router-link
              >
              <!-- <router-link class="collapse-item" to="/uniqueid-scans">UNIQUE ID</router-link> -->
              <router-link class="collapse-item" to="/missing-information"
                >MISSING INFO</router-link
              >
            </div>
          </div>
          <router-link class="collapse-item" to="/search"
            >DEEP SEARCH</router-link
          >
        </div>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse1"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-credit-card"></i>
        <span>PAYMENT</span>
      </a>
      <div
        id="collapse1"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">S4P Payment</h6>
          <router-link class="collapse-item" to="/unbilled"
            >UNBILLED</router-link
          >
          <router-link class="collapse-item" to="/s4p-citations"
            >CITATIONS</router-link
          >
          <router-link class="collapse-item" to="/s4p-invoices"
            >INVOICES</router-link
          >
          <router-link class="collapse-item" to="/view-transaction"
            >S4P TRANSACTIONS</router-link
          >
          <h6 class="collapse-header">Paid Transactions</h6>
          <router-link class="collapse-item" to="/payments">PAID</router-link>
        </div>
      </div>
    </li>
    <hr class="sidebar-divider d-none d-md-block" />
    <li class="nav-item pl-1">
      <!-- <a class="nav-link" href="/dashboard"> -->
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseOnline"
        aria-expanded="true"
        aria-controls="collapse"
      >
        <i class="fas fa-search"></i>
        <span>ONLINE LOOKUP</span>
      </a>
      <div
        id="collapseOnline"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link class="collapse-item" to="/distinct-lp"
            >DISTICT LP</router-link
          >
          <router-link class="collapse-item" to="/not-found"
            >NOT FOUND</router-link
          >
          <router-link class="collapse-item" to="/online-transactions"
            >ONLINE TRANSACTIONS</router-link
          >
        </div>
      </div>
    </li>
    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/invoicable-transactions"
        ><i class="fas fa-file-invoice"></i>
        <span>UNINVOICED</span></router-link
      >
    </li>
    <hr class="sidebar-divider d-none d-md-block" />
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapse2"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-file"></i>
        <span>MASTERFILES</span>
      </a>
      <div
        id="collapse2"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <div class="collapse-divider"></div>
          <h6 class="collapse-header">Client Files:</h6>
          <router-link class="collapse-item" to="/scanned-files"
            >SCANNED FILES</router-link
          >
          <router-link class="collapse-item" to="/collection"
            >COLLECTION SCANS</router-link
          >
          <router-link class="collapse-item" to="/transactions"
            >SRT TRANSACTIONS</router-link
          >
          <router-link class="collapse-item" to="/masterfile"
            >CLIENT FILES</router-link
          >
          <router-link class="collapse-item" to="/unprocessable-files"
            >ARCHIVED SCANS</router-link
          >
          <router-link class="collapse-item" to="/drop-box"
            >DROP BOX</router-link
          >
          <div class="collapse-divider"></div>
          <h6 class="collapse-header">ADDONS:</h6>
          <router-link class="collapse-item" to="/categories"
            >FILE CATEGORIES</router-link
          >
          <!-- <router-link class="collapse-item" to="/clients">Clients</router-link> -->
        </div>
      </div>
    </li>
    <hr class="sidebar-divider d-none d-md-block" />
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapsePages"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-fw fa-folder"></i>
        <span>ADDONS</span>
      </a>
      <div
        id="collapsePages"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">CLIENTS:</h6>
          <router-link class="collapse-item" to="/clients">CLIENTS</router-link>
          <h6 class="collapse-header">AGENCIES</h6>
          <router-link class="collapse-item" to="/agencies"
            >ALL AGENCIES</router-link
          >
          <router-link class="collapse-item" to="/srt-agencies"
            >SRT AGENCIES</router-link
          >
          <router-link class="collapse-item" to="/citation-agencies"
            >CITATION AGENCIES</router-link
          >
          <!-- <router-link class="collapse-item" to="/account-agencies">Toll Accounts Agency</router-link>
          <router-link class="collapse-item" to="/payment-agencies">Payment Agency</router-link> -->
          <div class="collapse-divider"></div>
          <h6 class="collapse-header">OTHER:</h6>
          <!-- <router-link class="collapse-item" to="/agency-accounts">Agency Accounts</router-link>
          <router-link class="collapse-item" to="/agency-coverage">Agency Coverage</router-link> -->
          <router-link class="collapse-item" to="/states">STATES</router-link>
        </div>
      </div>
    </li>
    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseReports"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-file-alt"></i>
        <span>REPORTS</span>
      </a>
      <div
        id="collapseReports"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link class="collapse-item" to="/daily-report"
            >DAILY REPORT</router-link
          >
          <router-link class="collapse-item" to="/user-report"
            >USER REPORT</router-link
          >
        </div>
      </div>
    </li>
    <!-- <li class="nav-item pl-1">
      <router-link class="nav-link" to="/daily-report"><i class="fas fa-file-alt"></i>
        <span>DAILY REPORT</span></router-link>
    </li> -->
    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button
        class="rounded-circle border-0"
        id="sidebarToggle"
        @click="goBack"
      ></button>
    </div>
  </ul>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      role: "",
      // username: "",
    };
  },
  methods: {
    goBack() {
      // Navigate back in the browser history
      window.history.back();
      window.location.reload();
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.username = this.currentUser.username;
    this.role = localStorage.getItem("role");
    this.isAdmin = this.role === "Supervisor";
    console.log(this.role, "role");
    console.log("isAdmin:", this.isAdmin);
  },
};
</script>
